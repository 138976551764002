import { render, staticRenderFns } from "./ReservationList.vue?vue&type=template&id=908c20ce&scoped=true&"
import script from "./ReservationList.vue?vue&type=script&lang=js&"
export * from "./ReservationList.vue?vue&type=script&lang=js&"
import style0 from "./ReservationList.vue?vue&type=style&index=0&id=908c20ce&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "908c20ce",
  null
  
)

export default component.exports