<template>
  <div class="container">
    <Header>
      <template v-slot:title>
        <span>{{ $t('bookingList') }}</span>
      </template>
    </Header>
    <div class="main">
      <div class="reservation">
        <div class="list">
          <div class="list-item" v-for="(reservation, index) of reservations" :key="index">
            <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
              <div>
                <div>{{ $t('bookingNumber') }}:</div>
                <div>{{ reservation.booking_number }}</div>
              </div>

              <div :class="getClassName(reservation)">
                {{ $t(`reservationStatus.${getStatus(reservation)}`) }}
              </div>
            </div>
            <van-divider />
            <van-row type="flex" gutter="8" @click="goReservationDetail(reservation)">
              <van-col span="8">
                <van-image height="100" lazy-load fit="cover" :src="reservation.hotel.image" />
              </van-col>
              <van-col span="16">
                <div class="hotel-name">{{ reservation.hotel.name }}</div>
                <div class="breakfast">{{ reservation.arrival }} ~ {{ reservation.departure }}  {{ reservation.nights }}{{ $t('nights') }}</div>
                <div class="breakfast" style="display: flex; flex-direction: row; align-items: center; justify-content: space-between;">
                  <div>{{ reservation.roomType.breakfast }}</div>
                  <div>{{ $t('totalAmount') }}</div>
                  <div class="rate">{{ reservation.currency_code }}{{ reservation.total_amount | formatNumber }}</div>
                </div>
              </van-col>
            </van-row>

            <div v-if="reservation.status !== 'Cancelled'" style="display: flex;  flex-direction: row; justify-content: space-around; padding: 10px 0;">
              <van-button
                v-if="reservation.payment_status === 'Unpaid' && reservation.status === 'Reserved'"
                style="width: 26%;"
                size="small"
                plain
                type="danger"
                @click="cancelReservation(reservation)"
              >
                {{ $t('cancel') }}
              </van-button>
              <van-button
                style="width: 26%;"
                size="small"
                plain
                type="primary"
                @click="goInvoice(reservation)"
              >
                {{ $t('invoice') }}
              </van-button>
              <van-button
                v-if="reservation.payment_status === 'Unpaid' && reservation.status !== 'No Show'"
                style="width: 26%;"
                size="small"
                type="warning"
                @click="goPayment(reservation)"
              >
                {{ $t('payNow') }}
              </van-button>
            </div>

            <van-divider />
          </div>
        </div>

        <van-empty v-if="reservations.length < 1" image="search" :description="$t('no booking')" />

      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant'
import Header from '@/components/Header'
// import { formatDate } from '@/filters'

export default {
  name: 'ReservationList',
  components: {
    Header
  },
  data () {
    return {
      loading: false,
      reservations: []
    }
  },
  computed: {

  },
  async mounted () {
    await this.loadReservation()
  },
  methods: {
    getClassName (reservation) {
      return {
        [this.getStatus(reservation)]: true
      }
    },
    getStatus (reservation) {
      if (reservation.payment_status === 'Unpaid') {
        if (reservation.status === 'Cancelled' || reservation.status === 'No Show') {
          return reservation.status
        }

        return reservation.payment_status
      }

      return reservation.status
    },
    async loadReservation () {
      try {
        const res = await this.$axios.get('/reservation')
        this.reservations = res.data
      } catch (e) {
        Notify(e.message)
      }
    },
    async cancelReservation (reservation) {
      const _this = this
      this.$dialog.confirm({
        message: this.$t('confirm to cancel this booking') + '?',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            _this.loading = true
            _this.$axios.put(`/reservation/${reservation.id}/cancel`)
              .then(() => {
                _this.loading = false
                _this.loadReservation()
                done()
              })
              .catch(() => {
                _this.loading = false
                _this.loadReservation()
                done()
              })
          } else {
            if (_this.loading) {
              done(false)
            } else {
              done()
            }
          }
        }
      })
        .catch(() => {
          _this.loadReservation()
        })
    },
    goPayment (reservation) {
      if (reservation.payment_method === 'NotSelected') {
        this.$router.push({
          name: 'Payment',
          params: { reservationId: reservation.id }
        })
      } else {
        this.$router.push({
          name: reservation.payment_method,
          params: { reservationId: reservation.id }
        })
      }
    },
    goInvoice (reservation) {
      this.$router.push({
        name: 'Invoice',
        params: { reservationId: reservation.id }
      })
    },
    goReservationDetail (reservation) {
      this.$router.push({
        name: 'ReservationDetail',
        params: { reservationId: reservation.id }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.main {
  padding-top: 64px;
  padding-bottom: 50px;
}

.reservation {
  padding: 1rem;
}

.hotel-name {
  margin-top: .3rem;
  font-size: 1.2rem;
}

.room-type-name {
  margin-top: .3rem;
  font-size: 1rem;
  color: #2766ba;
}

.breakfast {
  color: #5c5c5c;
  margin-top: .3rem;
}

.Cancelled {
  color: #999999;
}

.Unpaid {
  color: #f3ab0f;
}
</style>
